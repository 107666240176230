// Global
.page {
  &-title {
    font-size: 11px;
    font-weight: 800;
    line-height: 15px;
    letter-spacing: 0.24em;
  }

  &-info {
    h1 {
      font-size: 32px;
      font-weight: 700;
      color: $dark-gray;
      line-height: 37.5px;
      margin-bottom: 0.3em;
      font-family: "Roboto", sans-serif;

      @include minDevice(768px) {
        font-size: 36px;
        line-height: 49.95px;
      }
    }

    p {
      font-size: 15px;
      font-weight: 600;
      line-height: 20.46px;
      color: rgba($gray-3, 0.8);

      @include minDevice(768px) {
        font-size: 18px;
        margin-bottom: 5px;
        line-height: 25.58px;
      }
    }

    strong {
      line-height: 19.1px;
      font-weight: 700;
      font-size: 14px;
    }

    &-step {
      margin-bottom: 15px;

      &-count {
        margin-right: 20px;

        @include maxDevice(767px) {
          margin-right: 15px;
        }

        img {
          max-width: 56px;
          display: block;

          @include maxDevice(767px) {
            max-width: 49px;
          }
        }
      }

      h1 {
        margin: 0;

        @include maxDevice(767px) {
          font-size: 23px;
          line-height: 1.2;
        }

        @include maxDevice(479px) {
          font-size: 18px;
          line-height: 1.2;
        }
      }
    }
  }
}

// home
.home {
  &-image {
    position: absolute;
    top: 20px;
    z-index: 5;
    left: 0;
  }

  &-content {
    padding-top: 160px;
    padding-bottom: 26px;

    h1 {
      color: $dark-blue;
      font-size: 30px;
      font-weight: 700;
      line-height: 37.79px;

      sup {
        top: -2em;
      }
    }

    p {
      color: $dark-blue;
    }
  }

  @include maxDevice(375px) {
    &-image {
      width: 160px;
    }

    &-content {
      padding-top: 120px;
      h1 {
        font-size: 25px;
      }
    }
  }
}

// form
.form {
  &-group {
    margin-bottom: 8px;

    label {
      text-transform: uppercase;
      letter-spacing: 0.24em;
      margin-bottom: 8px;
      line-height: 15px;
      color: $dark-gray;
      font-weight: 800;
      font-size: 11px;
      display: block;

      sup {
        top: 0;
        left: 2px;
        color: $primary;
        font-size: inherit;
      }
    }

    & + .form-group {
      margin-top: 20px;
    }
  }

  &-control {
    width: 100%;
    height: 48px;
    outline: none;
    font-size: 14px;
    font-weight: 700;
    color: $dark-gray;
    padding: 6px 15px;
    border-radius: 4px;
    line-height: 19.1px;
    border: 1px solid $border;
    background-color: rgba($gray, 0.1);

    &::-webkit-input-placeholder {
      color: $gray-3;
    }
    &::-moz-placeholder {
      color: $gray-3;
    }
    &:-ms-input-placeholder {
      color: $gray-3;
    }
    &:-moz-placeholder {
      color: $gray-3;
    }
  }

  &-icon {
    top: 18px;
    padding: 0;
    right: 15px;
    border: none;
    position: absolute;
    background-color: transparent;
    cursor: pointer;

    img {
      display: block;
    }
  }
}

textarea.form-control {
  padding-top: 10px;
  padding-bottom: 10px;
}

// steps

.accordion-subitem {
  &:last-child {
    .accordion-checkbox {
      border-bottom: none;
    }
  }
}

.accordion {
  &-header {
    cursor: pointer;
    border-bottom: 1px solid $border;
  }

  &-body {
    overflow: hidden;
    max-height: 0;
    @include transition(max-height 0.5s ease-out);

    &.d-block {
      display: block !important;
    }
  }

  &-result {
    color: $black;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.1px;

    &-title {
      font-size: 11px;
      min-width: 135px;
      font-weight: 800;
      line-height: 15px;
      color: $dark-gray;
      margin-right: 10px;
      letter-spacing: 0.24em;
      text-transform: uppercase;
    }
  }

  .accordion-checkbox {
    &:last-child {
      border-bottom: none;
    }
  }

  &-item {
    color: $dark-gray;

    .accordion-checkbox {
      &:last-child {
        border-bottom: none;
      }
    }

    &.active {
      color: $black;

      .accordion {
        &-arrow {
          color: inherit;

          svg {
            @include rotate(180deg);
          }
        }

        &-header {
          border-bottom: 2px solid currentColor !important;
        }

        &-body {
          max-height: unset;
        }
      }
    }

    &.no-group {
      color: transparent;
    }

    h4 {
      text-transform: uppercase;
      letter-spacing: 0.16em;
      line-height: 20.46px;
      font-weight: 800;
      font-size: 15px;
      margin: 22px 0;

      @include maxDevice(767px) {
        margin: 18px 0;
        font-size: 14px;
      }
    }
  }

  &-arrow {
    border: none;
    cursor: pointer;
    padding: 0 15px;
    color: $light-gray;
    background-color: transparent;

    @include maxDevice(767px) {
      padding: 0 8px;
    }

    svg {
      @include rotate(0deg);
      @include transition(all 0.35s linear);
    }
  }

  &-checkbox {
    position: relative;
    border-bottom: 1px solid $border;

    input:checked + label {
      .accordion-checkbox-circle {
        border-color: $blue;
        box-shadow: 0 0 0 3px $white inset, 0 0 0 15px $blue inset;
      }
    }

    &-list {
      margin-bottom: 25px;

      .accordion-checkbox {
        &:last-child {
          border-bottom: none;
        }

        &-header {
          padding-top: 12px;
          padding-bottom: 12px;

          h5 {
            margin: 0;
            color: $black-3;
          }
        }
      }
    }

    &-header {
      cursor: pointer;
      padding-top: 20px;
      padding-bottom: 10px;

      @include minDevice(768px) {
        padding-right: 6px;
      }

      h6 {
        font-size: 13px;
        font-weight: 700;
        color: $dark-gray;
        margin: 8px 0 4px;
        line-height: 17.73px;
      }

      h5 {
        color: $black;
        font-size: 16px;
        margin: 0 0 23px;
        font-weight: 700;
        line-height: 21.82px;
      }

      & > div {
        & > h5 {
          margin-bottom: 2px;
        }

        img {
          display: block;
        }
      }

      p {
        color: $gray-3;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 5px;
        line-height: 17.73px;
      }
    }

    &-body {
      overflow: hidden;
      max-height: 0;
      @include transition(max-height 0.5s ease-out);
    }

    &-item {
      margin-bottom: 15px;

      @include minDevice(768px) {
        padding-right: 14px;
      }

      @include maxDevice(767px) {
        padding-right: 7px;
      }

      img {
        display: inline-block;
        vertical-align: -2px;
      }

      a {
        img {
          display: block;
          cursor: pointer;
          pointer-events: all !important;
        }
      }
    }

    &-circle {
      min-width: 32px;
      min-height: 32px;
      width: 32px;
      height: 32px;
      box-shadow: none;
      margin-left: auto;
      border-radius: 32px;
      border: 2px solid $light-gray;

      @include flexbox();
      @include transition(all 0.35s linear);

      img {
        left: 0px;
        width: 13px;
        margin: auto;
        position: relative;
      }
    }

    &.active {
      .btn-show {
        display: none;
      }

      .btn-hide {
        display: block;
      }

      .accordion {
        &-btns {
          margin-bottom: 12px;
          position: relative;
          bottom: 0;
        }
        &-checkbox {
          &-header {
            h5 {
              margin-bottom: 0;
            }
          }

          &-body {
            max-height: 1500px;
          }
        }
      }
    }
  }

  &-btns {
    left: 0;
    bottom: 0px !important;
    margin-bottom: 12px;
    position: absolute;
    pointer-events: all !important;

    &-normal {
      position: relative;
      bottom: 14px;
    }

    button {
      cursor: pointer;
      color: $primary;
      font-size: 13px;
      font-weight: 700;
      line-height: 17.73px;

      & + .btn-link {
        margin-left: 35px;
      }

      svg {
        width: 8px;
        height: 6px;
        margin-left: 5px;
        color: $light-gray;
        vertical-align: 1px;
        transition: all 0.35s linear;
      }

      &.btn-hide {
        display: none;

        svg {
          @include rotate(180deg);
          color: $black;
        }
      }
    }
  }

  &-product {
    color: $black;
    font-weight: 700;
    font-size: 13.92px;
    line-height: 18.98px;

    & > div {
      margin-bottom: 12px;
    }

    &-title {
      line-height: 19.1px;
      font-weight: 700;
      font-size: 14px;
      min-width: 190px;
    }
  }
}

.request-detail-container {
  display: flex;
  @include maxDevice(400px) {
    display: block;
    .accordion-product-title {
      padding: 5px 0px;
    }
  }
}

.aci {
  &-dot {
    min-width: 12px;
    min-height: 12px;
    width: 12px;
    height: 12px;
    margin-right: 15px;
    border-radius: 12px;
    border: 1px solid #c5c1c1;
    @include maxDevice(767px) {
      margin-right: 10px;
    }
  }

  &-name {
    line-height: 20.46px;
    color: $dark-gray;
    font-weight: 700;
    font-size: 15px;
  }

  &-status {
    line-height: 16.37px;
    color: $medium-gray;
    margin-right: 30px;
    margin-left: auto;
    font-weight: 700;
    font-size: 12px;
    min-width: 70px;

    @include maxDevice(767px) {
      margin-right: 20px;
      min-width: 65px;
    }

    img {
      margin-right: 10px;

      @include maxDevice(767px) {
        margin-right: 6px;
      }
    }
  }
}

.group-title {
  color: $blue;
  font-size: 11px;
  margin: 12px 0 12px;
  line-height: 15px;
  letter-spacing: 0.24em;
}

.selected-list {
  margin-bottom: 35px;

  h5 {
    line-height: 21.82px;
    font-weight: 800;
    color: $black-3;
    font-size: 16px;
    padding: 5px 0;
    margin: 0;
  }
}

// popup
.modal {
  @include transition(all 0.35s linear);
  background-color: rgba($gray-4, 0.47);
  @include flexbox();
  visibility: hidden;
  position: fixed;
  overflow: auto;
  padding: 15px;
  z-index: 999;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;

  &.active {
    opacity: 1;
    visibility: visible;

    .modal-content {
      @include translate(0, 0);
    }
  }

  &-content {
    width: 90%;
    margin: auto;
    max-width: 620px;
    position: relative;
    border-radius: 8px;
    padding: 40px 25px 24px;
    background-color: $white;
    border: 1px solid $border;
    @include translate(0, 25px);
    @include transition(all 0.35s linear);

    h2 {
      margin-top: 0;
      margin-bottom: 24px;
    }
  }
}

.thank-you {
  margin-top: 2px;
  padding-top: 52px;

  h1 {
    font-size: 32px;
    font-weight: 700;
    color: $dark-gray;
    line-height: 37.5px;
    margin: 20px 0 16px;
    font-family: "Roboto", sans-serif;
  }

  p {
    color: $gray-3;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 65px;
    line-height: 20.46px;
  }

  &-logo {
    margin-bottom: 70px;
  }
}

// spinner
$size: 60px;
.spinner {
  position: absolute;
  top: calc(50% - #{$size / 2});
  left: calc(50% - #{$size / 2});
  width: $size;
  height: $size;
  z-index: 1;

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: white;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-info-link {
  cursor: pointer;
  border: none;
  background-color: transparent;
  text-decoration: underline;
}

.bounceRight {
  animation: bounceRight 2s infinite;
  float: right;
}

/* right bounce */
@-webkit-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(5px);
  }
}
@-moz-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(5px);
  }
}
@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
  60% {
    -ms-transform: translateX(5px);
    transform: translateX(5px);
  }
}

.sub-item-comment {
  padding-left: 10px;
  padding-bottom: 3px;
  vertical-align: middle;
  display: initial !important;
}

table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: rgb(251, 188, 4);
  color: white;
}

#pagination-first-page,
#pagination-previous-page,
#pagination-next-page,
#pagination-last-page {
  background-color: transparent;
}

.btn-back {
  width: 24px;
  img {
    max-width: 24px;
  }
}

.rdt_TableHeader {
  display: contents !important;
}
.disclaimer {
  font-size: 14px;
}

.small-button {
  padding: 10px;
  font-size: 14px;
  box-shadow: 0 5px 16px 1px rgba(0, 0, 0, 0.24);
}

.admin-container {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
}

.button-glow {
  border: 1px solid;
  padding: 0px 20px;
  border-radius: 5px;
  height: 60px;
}

.parent-arrow {
  padding-top: 5px;
  padding-right: 8px;
  svg {
    width: 15px;
    height: 15px;
    text-align: center;
    color: #a6a8ab;
  }
}
