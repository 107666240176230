.footer {
  padding-top: 18px;
  padding-bottom: 20px;

  &-gray {
    background-color: rgba($gray, 0.1);
    border-top: 1px solid $border;
    padding: 20px 25px;
  }

  &-inner {
    padding: 30px 0;

    @include maxDevice(767px) {
      padding: 18px 0;
    }
  }

  &-step {
    margin-right: 10px;
    padding-top: 5px;
    @include maxDevice(767px) {
      margin-right: 12px;
    }

    svg {
      width: 31px;
      height: 29px;
      display: block;
      margin: 0 !important;
    }

    img {
      width: 25px;
    }
  }

  &-link {
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    line-height: 16.41px;
    font-weight: 700;

    svg {
      margin-left: 15px;

      @include maxDevice(767px) {
        margin-left: 12px;
      }
    }

    @include maxDevice(400px) {
      font-size: 14px;
    }
  }
}
