$primary: #e31f26;
$blue: #00b0eb;
$dark-blue: #2d3985;
$light-blue: #b3def7;
$orange: #e76c34;
$orange-dark: #df6d35;
$green: #b6cb51;
$green-a: #afc654;

$white: #fff;
$black: #000;
$black-3: #333;
$gray: #c4c4c4;
$gray-3: #828282;
$gray-2: #dfdfd7;
$gray-4: #464646;
$medium-gray: #808285;
$dark-gray: #59595c;
$light-gray: #a6a8ab;
$border: #f2f2eb;

:root {
  --app-height: 100%;
  --notch-margin: 0px;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 100.01%;
}

#root {
  padding-top: var(--notch-margin);
}

body {
  font-family: "Nunito Sans", sans-serif;
  line-height: 22px;
  min-height: 100vh;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}

a {
  color: $blue;

  &:hover {
    color: inherit;
    cursor: pointer;
  }
}

textarea {
  height: auto !important;
}

.left {
  float: left;
}
.right {
  float: right;
}
.text-danger {
  color: $primary;
  font-size: 14px;
  font-weight: 700;
  padding-top: 5px;
}
.text-success {
  color: $green;
  font-size: 14px;
  font-weight: 700;
  padding-top: 5px;
}
button:disabled {
  background: $gray;
}

#root,
main {
  overflow-x: hidden !important;
}

.no-padding {
  padding: 0 !important;
}

.container {
  padding-right: 35px;
  margin-right: auto;
  padding-left: 35px;
  margin-left: auto;
  max-width: 920px;
  display: block;
  width: 100%;

  @include maxDevice(767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.disabled {
  opacity: 0.3 !important;
  pointer-events: none !important;
}
sup {
  top: -1.4em;
  font-size: 7px;
  font-weight: 900;
  line-height: 6.82px;
}

a,
.btn,
button {
  outline: none !important;
}

strong {
  font-weight: 800;
}

a,
.btn span,
.btn img,
button,
input {
  @include transition(all 0.35s linear);
}

button,
input {
  &:focus {
    box-shadow: none !important;
  }
}

.no-shadow {
  box-shadow: none !important;
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

.vh-100 {
  height: var(--app-height);
}

.m-auto {
  margin: auto;
}

h1 {
  margin: 0 0 0.4em;
}

h2 {
  font-weight: 800;
  color: $dark-gray;
  font-size: 23.44px;
  margin-bottom: 22px;
  line-height: 31.97px;
}

p {
  margin: 0;

  & + p {
    margin-top: 15px;
  }
}

.btn {
  border: none;
  font-weight: 800;
  border-radius: 5px;
  white-space: nowrap;
  text-decoration: none;
  @include transition(0.25s all linear);

  &:hover {
    cursor: pointer;
    box-shadow: 0 5px 16px 1px rgba(0, 0, 0, 0.24);
  }

  &-xl {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  &-lg {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &-login {
    padding-left: 42px;
    padding-right: 42px;
  }

  &-reset {
    padding: 0;
    border: none;
    min-height: auto;
    background-color: transparent;
  }

  &-block {
    width: 100%;
    display: block;
  }

  &-close {
    position: absolute;
    z-index: 99;
    right: 17px;
    top: 18px;
    cursor: pointer;
  }

  &-link {
    font-size: 13px;
    font-weight: 700;
    text-decoration: none;
    border-bottom: 1px solid currentColor;

    &-sm {
      font-size: 11px;
    }
  }

  &-back {
    img {
      display: block;

      @include maxDevice(767px) {
        max-width: 16px;
      }
    }
  }

  &-user {
    margin-left: auto;
    padding: 20px 17px 14px;
    background-color: $white;
    border: 1px solid $border;
    border-radius: 0 0 10px 10px;
    cursor: pointer;

    @include maxDevice(991px) {
      border-radius: 0 0 0 10px;
      position: absolute;
      top: -6px;
      right: 0;
    }

    img {
      display: block;
    }
  }
}

.text {
  &-red {
    color: $primary;
  }
  &-green {
    color: $green;
  }
  &-white {
    color: $white !important;
  }
  &-gray-3 {
    color: $gray-3;
  }
  &-dark-gray {
    color: $dark-gray !important;
  }
  &-blue {
    color: $blue;
  }
  &-light-blue {
    color: $light-blue;
  }
  &-orange-dark {
    color: $orange-dark;
  }

  &-center {
    text-align: center;
  }
  &-right {
    text-align: right;
  }
}

.bg {
  &-red {
    background-color: $primary;
  }
  &-green {
    background-color: $green-a;
  }
  &-blue {
    background-color: $blue;
  }
  &-light-blue {
    background-color: $light-blue;
  }
  &-white {
    background-color: $white;
  }
  &-orange {
    background-color: $orange;
  }
  &-orange-dark {
    background-color: $orange-dark;
  }
  &-gray-light {
    background-color: rgba($gray, 0.1);
  }
}

.rounded {
  &-0 {
    border-radius: 0 !important;
  }
}

.position {
  &-relative {
    position: relative;
  }
  &-absolute {
    position: absolute;
  }
}

.p-0 {
  padding: 0 !important;
}

.py {
  &-20 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  &-25 {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  &-md-25 {
    @include minDevice(768px) {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
}

.pt {
  &-40 {
    padding-top: 40px;
  }
  &-20 {
    padding-top: 20px;
  }
  &-10 {
    padding-top: 10px;
  }
}

.px {
  &-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &-sm {
    &-15 {
      @include maxDevice(767px) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &-0 {
      @include maxDevice(767px) {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}

.pb {
  &-0 {
    padding-bottom: 0 !important;
  }

  &-2 {
    padding-bottom: 2px;
  }
  &-12 {
    padding-bottom: 12px;
  }

  &-15 {
    padding-bottom: 15px;
  }
  &-50 {
    padding-bottom: 50px;
  }
}

.mt {
  &-auto {
    margin-top: auto;
  }
}

.ml-auto {
  margin-left: auto;
}

.ml {
  &-35 {
    margin-left: 35px;
  }
  &-20 {
    margin-left: 20px;
  }
}

.mb {
  &-18 {
    margin-bottom: 18px;
  }

  &-25 {
    margin-bottom: 25px;
  }

  &-10 {
    margin-bottom: 10px !important;
  }

  &-8 {
    margin-bottom: 8px !important;
  }

  &-0 {
    margin-bottom: 0 !important;
  }
}

.overflow {
  &-auto {
    overflow: auto;
  }
}

.fw-800 {
  font-weight: 800 !important;
}

.underline {
  text-decoration: underline;
}
