.header {
  min-height: 120px;

  &-gray {
    background-color: rgba($gray, 0.1);
    border-bottom: 1px solid $border;
  }

  &-auto {
    min-height: auto;
  }

  .page-info {
    padding: 5px 15px 14px;
  }

  .login-info {
    padding: 5px 0px 14px;
  }

  &-control {
    min-height: 60px;
    margin-bottom: 20px;

    @include maxDevice(991px) {
      margin-top: -10px;
      margin-bottom: 25px;
    }

    @include maxDevice(767px) {
      margin-bottom: 0px;
    }
  }

  &-dots {
    margin-left: 24px;

    span {
      background-color: $dark-gray;
      border-radius: 6px;
      margin-right: 6px;
      opacity: 0.3;
      height: 6px;
      width: 6px;

      &.active {
        opacity: 1;
      }
    }
  }
}

.navbar {
  @include transition(all 0.35s linear);
  background-color: rgba($gray-4, 0.47);
  visibility: hidden;
  position: fixed;
  z-index: 999;
  width: 100%;
  opacity: 0;
  right: 0;
  top: 0;
  margin-top: var(--notch-margin);

  &.active {
    opacity: 1;
    visibility: visible;

    .navbar-content {
      right: 0;
    }
  }

  &-content {
    @include transition(all 0.35s linear);
    background-color: $white;
    position: absolute;
    max-width: 550px;
    right: -330px;
    width: 95%;
  }

  &-header {
    padding: 23px 30px 20px;

    h2 {
      margin: 0;
      color: $primary;
      font-weight: 800;
      font-size: 23.44px;
      line-height: 31.97px;
    }
  }

  &-profile {
    font-size: 16px;
    color: $black-3;
    font-weight: 700;
    line-height: 21.82px;
    text-decoration: none;

    img {
      display: inline-block;
      vertical-align: -5px;
      margin-right: 10px;
    }
  }

  &-body {
    padding: 0 30px 15px;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        a {
          color: $black-3;
          font-size: 16px;
          font-weight: 800;
          line-height: 21.82px;
          text-decoration: none;
          padding: 20px 0;
          display: block;
          @include flexbox();
          @include align-items(center);

          svg {
            margin-left: auto;
          }
        }
        & + li {
          border-top: 1px solid $border;
        }
      }
    }
  }
}
