.d {
  &-inline-block {
    display: inline-block;
  }

  &-flex {
    @include flexbox();
  }

  &-md-flex {
    @include minDevice(768px) {
      @include flexbox();
    }
  }
}

.align-items-center {
  @include align-items(center);
}

.align-items-end {
  align-items: end;
}
.justify-content {
  &-center {
    @include justify-content(center);
  }

  &-end {
    @include justify-content(flex-end);
  }
  &-space-between {
    @include justify-content(space-between);
  }
}

.flex {
  &-wrap {
    @include flex-wrap(wrap);
  }
  &-nowrap {
    @include flex-wrap(nowrap);
  }
  &-column {
    @include flex-direction(column);
  }
  &-row {
    @include flex-direction(row);
  }
  &-grow {
    flex-grow: 1;
  }
}
